import React, { useEffect, useState, useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Lissajous from '../../animations/Lissajous/Lissajous';
import ProductCard from './ProductCard';
import { logoTalkwell } from '../../../assets/iconURIs';
import StandingWave from '../../animations/StandingWave/StandingWave';
import {
  imageEugeneProfile,
  imageGCPLogo,
  imageMSLogo,
  imageOpenAILogo,
  imagePostgresLogo,
  imageTSLogo,
  imagePytorchLogo,
  imageAmplitudeLogo,
  imagePostHogLogo,
  imageFirebaseLogo,
} from '../../../assets/imageURIs';
import { breakpoints } from '../../../utils/Breakpoints';
import SquareWave from '../../animations/SquareWave/SquareWave';
import ConsultingTechnicalItem from './ConsultingTechnicalItem';
import WebsiteIcon from '../../icons/Website/Website';
import LinkedInIcon from '../../icons/LinkedIn/LinkedIn';
import TwitterIcon from '../../icons/Twitter/Twitter';
import MediumIcon from '../../icons/Medium/Medium';
import StackOverflowIcon from '../../icons/StackOverflow/StackOverflow';
import QuoteIcon from '../../icons/Quote/Quote';
import { useWindowSize } from '../../../hooks/useWindowSize';

const GlobalStyle = createGlobalStyle`
  body {
    padding: 0;
    margin: 0;
    font-family: Avenir, sans-serif;
  }
  p {
    margin: 10px 0;
  }
`;

const Header = styled.div`
  height:auto;
  display: flex;
  background-color: ${(props) => (props.compressHeader ? 'rgba(0,0,0,0.6)' : 'rgba(50,50,50,0.0)')};
  color: #ffffff;
  z-index:10;
  overflow:hidden;
  width: calc(100vw - 10px);
  margin-left:auto;
  margin-right:auto;
  position:fixed;
  padding: 5px;
  backdrop-filter: ${(props) => (props.compressHeader ? 'blur(5px)' : 'none')};
  
  -webkit-transition: background-color 300ms ease;
  -moz-transition: background-color 300ms ease;
  -o-transition: background-color 300ms ease;
  -ms-transition: background-color 300ms ease;
  transition: background-color 300ms ease;
`;

const HeaderLogo = styled.img`
  height: ${(props) => (props.compressHeader ? '60px' : '80px')};
  -webkit-transition: height 300ms ease;
  -moz-transition: height 300ms ease;
  -o-transition: height 300ms ease;
  -ms-transition: height 300ms ease;
  transition: height 300ms ease;

  @media only screen and (max-width: ${breakpoints.small}) {
    height: 60px;
  }
`;

const HeaderButtons = styled.div`
  margin-left: auto;
  margin-right: 30px;
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: ${breakpoints.small}) {
    margin-right: 5px;
    gap: 10px;
    margin-left: auto;
  }
`;

const HeaderButtonProducts = styled.div`
  cursor: pointer;
  font-size:17px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const HeaderButtonConsulting = styled.div`
  cursor: pointer;
  font-size:17px;
  padding: 4px 6px;
  background-color: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 20px;
`;

const PageScroll = styled.div`
  margin-top: 100px;
  position: absolute;
  width: calc(100% - 40px);
  justify-content: center;
  padding: 20px;
  flex-direction: column;
  display: flex;
  align-items: center;

  @media only screen and (max-width: ${breakpoints.small}) {
    padding: 10px;
    width: calc(100% - 20px);
    margin-top: 70px;
  }
`;

const colorArray = [
  [62, 35, 255],
  [60, 255, 60],
  [255, 35, 98],
  [45, 175, 230],
  [255, 0, 255],
  [255, 128, 0],
];

let step = 0;
const colorIndices = [0, 1, 2, 3];
const gradientSpeed = 0.002;

const BackgroundGradient = styled.div`
  background: ${(props) => props.gradient};
  width: 100vw;
  height: 100vh;
  position: fixed;
`;

const IntroMessage = styled.div`
  color: rgba(255,255,255,0.85);
  font-size: 18px;
  background-color: rgba(0,0,0,0.45);
  padding: 14px 20px;
  width: 600px;
  line-height: 36px;
  margin-bottom: 30px;
  border-radius: 8px;

  @media only screen and (max-width: ${breakpoints.small}) {
    width: calc(100% - 30px);
    padding: 8px 15px;
    margin-top: 10px;
  }
`;

const ProductsSection = styled.div`
  width: 100%;
  padding: 20px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 15px;
`;

const ConsultingSection = styled.div`
  width: 100%;
  padding: 20px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 15px;
`;

const ConsultingBlock = styled.div`
  border-radius: 8px;
  width: 640px;
  box-shadow: 2px 2px 3px 2px rgba(0,0,0,0.08);
  font-family: Avenir, sans-serif;
  color: rgba(255, 255, 255, 0.9);
  background-color: rgba(0,0,0,0.5);
  padding: 10px 20px 20px 20px;
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 32px;

  @media only screen and (max-width: ${breakpoints.small}) {
    width: calc(100% - 20px);
    padding: 10px 10px 20px 10px;
  }
`;

const ConsultingProfile = styled.img`
  width: 72px;
  height: 72px;
  margin-right: 12px;
  background-color: gray;
  border-radius: 36px;
`;

const ConsultingEnd = styled.div`
  display: flex;
  align-items: center;
`;

const SectionTitle = styled.div`
  font-size: 40px;
  color: rgba(255,255,255,0.9);
  text-align: center;
  margin-bottom: 10px;
`;

const VerticalBump = styled.div`
  height: 6px;
`;

const LissajousHolder = styled.div`
  margin: 10px;
  @media only screen and (max-width: ${breakpoints.small}) {
    margin: 0;
    width: 100%;
  }
`;

const StandingWaveHolder = styled.div`
  margin: -60px -20px -80px -20px;
  @media only screen and (max-width: ${breakpoints.small}) {
    margin: -60px -10px -80px -10px;
  }
`;

const SquareWaveHolder = styled.div`
  margin: 10px -20px 10px -20px;
  @media only screen and (max-width: ${breakpoints.small}) {
    margin: 10px -10px 10px -10px;
  }
`;

const EndMessage = styled.div`
  line-height: 20px;
`;

const FooterDisclaimer = styled.div`
  position: absolute;
  bottom: -30px;
  right: 20px;
  color: rgba(255, 255, 255, 0.65);
  @media only screen and (max-width: ${breakpoints.small}) {
    right: unset;
    text-align: center;
    bottom: -30px;
    margin: 0 auto;
  }
`;

const EndName = styled.div`
  font-size: 17px;
  color: rgba(255, 255, 255, 0.9);
`;

const EndTitle = styled.div`
  font-size: 15px;
  color: rgba(255, 255, 255, 0.9);
`;

const EndLocation = styled.div`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
`;

const ConsultingSeparator = styled.div`
  width: 100%;
  background-color: rgba(255,255,255,0.5);
  height: 1px;
  margin: 22px 0;
`;

const TechnicalItemsHolder = styled.div`
  width: 100%;
  gap: 10px;
  display: flex;
  align-items: center;
  margin: 6px 0 20px 0;

  @media only screen and (max-width: ${breakpoints.small}) {
    justify-content: center;
    flex-wrap: wrap;
  }
`;

const TestimonialSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  margin: 16px 0 10px 0;
`;

const Testimonial = styled.div`
  width: 550px;
  display: flex;

  @media only screen and (max-width: ${breakpoints.small}) {
    width: 100%;
  }
`;

const TestimonialTitle = styled.div`
  color: rgba(255, 255, 255, 0.9);
  font-size: 18px;
`;

const TestimonialSubtitle = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
`;

const TestimonialQuote = styled.div`
  color: rgba(255, 255, 255, 0.8);
  font-size: 15px;
  position: relative;
`;

const QuoteHolder = styled.div`
  position: absolute;
  top: 2px;
  left: -28px;
`;

const TestimonialTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const ConsultingBlurbHeader = styled.div`
  font-size: 20px;
  color: rgba(255,255,255,0.9)
`;

const SiteIconHolder = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background-color: rgba(255,255,255,0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: rgba(255,255,255,0.22);
  }
`;

const SiteIconTray = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
`;

function MainPage() {
  const [gradient, setGradient] = useState('');
  const [compressHeader, setCompressHeader] = useState(false);
  const productsSectionRef = useRef(null);
  const consultingSectionRef = useRef(null);
  const [width] = useWindowSize();

  const updateGradient = () => {
    const c00 = colorArray[colorIndices[0]];
    const c01 = colorArray[colorIndices[1]];
    const c10 = colorArray[colorIndices[2]];
    const c11 = colorArray[colorIndices[3]];

    const iStep = 1 - step;
    const r1 = Math.round(iStep * c00[0] + step * c01[0]);
    const g1 = Math.round(iStep * c00[1] + step * c01[1]);
    const b1 = Math.round(iStep * c00[2] + step * c01[2]);
    const color1 = `rgb(${r1},${g1},${b1})`;

    const r2 = Math.round(iStep * c10[0] + step * c11[0]);
    const g2 = Math.round(iStep * c10[1] + step * c11[1]);
    const b2 = Math.round(iStep * c10[2] + step * c11[2]);
    const color2 = `rgb(${r2},${g2},${b2})`;

    const gradientString = `-webkit-gradient(linear, left top, right top, from(${color1}), to(${color2}))`;
    setGradient(gradientString);
    step += gradientSpeed;
    if (step >= 1) {
      step %= 1;
      // eslint-disable-next-line prefer-destructuring
      colorIndices[0] = colorIndices[1];
      // eslint-disable-next-line prefer-destructuring
      colorIndices[2] = colorIndices[3];

      // Pick two new target color indices
      // Do not pick the same as the current one
      colorIndices[1] = (
        colorIndices[1] + Math.floor(1 + Math.random() * (colorArray.length - 1))
      ) % colorArray.length;
      colorIndices[3] = (
        colorIndices[3] + Math.floor(1 + Math.random() * (colorArray.length - 1))
      ) % colorArray.length;
    }
  };

  useEffect(() => {
    setInterval(updateGradient, 30);
    window.addEventListener('scroll', () => {
      setCompressHeader(window.scrollY > 65);
    });
  }, []);

  let lissajousSize = Math.min(width - 20, 460);
  if (lissajousSize === 0) {
    lissajousSize = 100;
  }

  return (
    <div>
      <GlobalStyle />
      <BackgroundGradient gradient={gradient} />
      <Header compressHeader={compressHeader}>
        <HeaderLogo
          compressHeader={compressHeader}
          src="https://s3-us-west-1.amazonaws.com/resonancestudios/overtoneLabs.png"
        />
        <HeaderButtons>
          <HeaderButtonProducts onClick={() => {
            window.scroll({
              top: productsSectionRef.current.offsetTop + 20,
              left: 0,
              behavior: 'smooth',
            });
          }}
          >
            Products
          </HeaderButtonProducts>
          <HeaderButtonConsulting onClick={() => {
            window.scroll({
              top: consultingSectionRef.current.offsetTop + 20,
              left: 0,
              behavior: 'smooth',
            });
          }}
          >
            Consulting
          </HeaderButtonConsulting>
        </HeaderButtons>
      </Header>
      <PageScroll>
        <LissajousHolder>
          <Lissajous size={lissajousSize} />
        </LissajousHolder>
        <IntroMessage>
          Overtone Labs is a forge where creativity, craft, and process converge
          to create elegant products that deliver value and leave a lasting positive impact.
          Areas of interest are
          {' '}
          <span style={{ color: '#ff7cd1' }}>communications</span>
          ,
          {' '}
          <span style={{ color: '#ad74f7' }}>healthcare</span>
          ,
          {' '}
          and
          {' '}
          <span style={{ color: '#7caeff' }}>music</span>
        </IntroMessage>
        <ProductsSection ref={productsSectionRef}>
          <SectionTitle>Products</SectionTitle>
          <ProductCard
            key="talkwell"
            imageURL={logoTalkwell}
            link="https://talkwell.com"
            description="An AI-powered communication tracker and coach for couples and families based on decades of research from the world's leading marriage researchers and scientists."
            title="Talkwell"
            tags="Communication, 🌎 Web"
          />
        </ProductsSection>
        <SquareWaveHolder>
          <SquareWave height={200} />
        </SquareWaveHolder>
        <ConsultingSection ref={consultingSectionRef}>
          <SectionTitle>Consulting</SectionTitle>
          <ConsultingBlock>
            <p>
              In addition to building in-house products,
              I&apos;m taking on select app, web, and machine learning development projects
              as a full-stack developer, data scientist, and designer.
            </p>
            <VerticalBump />
            <p>
              I&apos;ve been writing code for the past 15 years and have become an
              expert at rapidly developing and designing industry-grade applications
              and testing them in the market. I also have a M.S. in Computer Science
              with a focus in Machine Learning and Artificial Intelligence from Columbia University.
              This combined with my extensive experience as an entrepreneur has put me in
              a unique position to deliver value to my clients.
            </p>
            <VerticalBump />
            <p>
              It would be my honor to help bring your ideas to life and help your business succeed.
            </p>
            <VerticalBump />
            <ConsultingEnd>
              <ConsultingProfile src={imageEugeneProfile} />
              <EndMessage>
                <EndName>Eugene M. Joseph</EndName>
                <EndTitle>Founder & CEO</EndTitle>
                <EndLocation>San Francisco, CA</EndLocation>
              </EndMessage>
            </ConsultingEnd>
            <SiteIconTray>
              <SiteIconHolder onClick={() => {
                window.parent.open('https://eugenemjoseph.com', '_blank');
              }}
              >
                <WebsiteIcon color="rgba(255, 255, 255, 0.9)" size={20} />
              </SiteIconHolder>
              <SiteIconHolder onClick={() => {
                window.parent.open('https://linkedin.com/in/eugenemjoseph', '_blank');
              }}
              >
                <LinkedInIcon color="rgba(255, 255, 255, 0.9)" size={20} />
              </SiteIconHolder>
              <SiteIconHolder onClick={() => {
                window.parent.open('https://twitter.com/EugeneMJoseph', '_blank');
              }}
              >
                {' '}
                <TwitterIcon color="rgba(255, 255, 255, 0.9)" size={20} />
              </SiteIconHolder>
              <SiteIconHolder onClick={() => {
                window.parent.open('https://medium.com/@eugenemjoseph', '_blank');
              }}
              >
                <MediumIcon color="rgba(255, 255, 255, 0.9)" size={20} />
              </SiteIconHolder>
              <SiteIconHolder onClick={() => {
                window.parent.open('https://stackoverflow.com/users/2529253/eugene', '_blank');
              }}
              >
                <StackOverflowIcon color="rgba(255, 255, 255, 0.9)" size={20} />
              </SiteIconHolder>
            </SiteIconTray>
            <ConsultingSeparator />
            <ConsultingBlurbHeader>
              Languages & Databases
            </ConsultingBlurbHeader>
            <TechnicalItemsHolder>
              <ConsultingTechnicalItem
                imageURL="https://s3-us-west-1.amazonaws.com/resonancestudios/language_logos/swift_logo.png"
                title="Swift"
              />
              <ConsultingTechnicalItem
                imageURL="https://s3-us-west-1.amazonaws.com/resonancestudios/language_logos/obj_c_logo.png"
                title="Objective-C"
              />
              <ConsultingTechnicalItem
                imageURL="https://s3-us-west-1.amazonaws.com/resonancestudios/language_logos/nodejs_logo.png"
                title="Node.js"
              />
              <ConsultingTechnicalItem
                imageURL="https://s3-us-west-1.amazonaws.com/resonancestudios/language_logos/js_logo.png"
                title="Javascript"
              />
              <ConsultingTechnicalItem
                imageURL={imageTSLogo}
                title="Typescript"
              />
              <ConsultingTechnicalItem
                imageURL="https://s3-us-west-1.amazonaws.com/resonancestudios/language_logos/python_logo.png"
                title="Python"
              />
              <ConsultingTechnicalItem
                imageURL={imagePostgresLogo}
                title="PostgreSQL"
                spacing={9}
              />
              <ConsultingTechnicalItem
                imageURL="https://s3-us-west-1.amazonaws.com/resonancestudios/language_logos/mongo_logo.png"
                title="MongoDB"
              />
            </TechnicalItemsHolder>
            <ConsultingBlurbHeader>
              Infrastructure & Tools
            </ConsultingBlurbHeader>
            <TechnicalItemsHolder>
              <ConsultingTechnicalItem
                imageURL="https://s3-us-west-1.amazonaws.com/resonancestudios/language_logos/aws_logo_y.png"
                title="AWS"
              />
              <ConsultingTechnicalItem
                imageURL={imageGCPLogo}
                title="GCP"
              />
              <ConsultingTechnicalItem
                imageURL={imageMSLogo}
                title="Azure"
                spacing={8}
                bgColor="#8561c4"
              />
              <ConsultingTechnicalItem
                imageURL="https://s3-us-west-1.amazonaws.com/resonancestudios/language_logos/twlio_logo.png"
                title="Twilio"
              />
              <ConsultingTechnicalItem
                imageURL="https://s3-us-west-1.amazonaws.com/resonancestudios/language_logos/react_logo.png"
                title="React"
              />
              <ConsultingTechnicalItem
                imageURL="https://s3-us-west-1.amazonaws.com/resonancestudios/language_logos/stripe_logo.png"
                title="Stripe"
              />
              <ConsultingTechnicalItem
                imageURL={imageOpenAILogo}
                title="OpenAI"
              />
              <ConsultingTechnicalItem
                imageURL="https://s3-us-west-1.amazonaws.com/resonancestudios/language_logos/d3_logo.png"
                title="D3.js"
              />
            </TechnicalItemsHolder>
            <ConsultingBlurbHeader>
              Machine Learning
            </ConsultingBlurbHeader>
            <TechnicalItemsHolder>
              <ConsultingTechnicalItem
                imageURL="https://resonancestudios.s3-us-west-1.amazonaws.com/language_logos/scikitlearn.png"
                title="Scikit-Learn"
                spacing={8}
              />
              <ConsultingTechnicalItem
                imageURL="https://resonancestudios.s3-us-west-1.amazonaws.com/language_logos/tfLogo.png"
                title="TensorFlow"
                spacing={8}
              />
              <ConsultingTechnicalItem
                imageURL="https://resonancestudios.s3-us-west-1.amazonaws.com/language_logos/kerasLogo.png"
                title="Keras"
                spacing={16}
                bgColor="#d10000"
              />
              <ConsultingTechnicalItem
                imageURL="https://resonancestudios.s3-us-west-1.amazonaws.com/language_logos/lightgbm.png"
                title="LightGBM"
              />
              <ConsultingTechnicalItem
                imageURL="https://resonancestudios.s3-us-west-1.amazonaws.com/language_logos/catboost.png"
                title="CatBoost"
                spacing={10}
                bgColor="lightblue"
              />
              <ConsultingTechnicalItem
                imageURL="https://resonancestudios.s3-us-west-1.amazonaws.com/language_logos/huggingFace.png"
                title="HuggingFace"
                spacing={10}
              />
              <ConsultingTechnicalItem
                imageURL="https://resonancestudios.s3-us-west-1.amazonaws.com/language_logos/spacyLogo.png"
                title="Spacy"
                spacing={9}
              />
              <ConsultingTechnicalItem
                imageURL={imagePytorchLogo}
                title="PyTorch"
                spacing={14}
              />
            </TechnicalItemsHolder>
            <ConsultingBlurbHeader>
              Design Tools & Analytics
            </ConsultingBlurbHeader>
            <TechnicalItemsHolder>
              <ConsultingTechnicalItem
                imageURL="https://s3-us-west-1.amazonaws.com/resonancestudios/language_logos/html_logo.png"
                title="HTML"
              />
              <ConsultingTechnicalItem
                imageURL="https://s3-us-west-1.amazonaws.com/resonancestudios/language_logos/css_logo.png"
                title="CSS"
              />
              <ConsultingTechnicalItem
                imageURL="https://s3-us-west-1.amazonaws.com/resonancestudios/language_logos/ps_logo_y.png"
                title="Photoshop"
              />
              <ConsultingTechnicalItem
                imageURL="https://s3-us-west-1.amazonaws.com/resonancestudios/language_logos/ai_logo_x.png"
                title="Illustrator"
              />
              <ConsultingTechnicalItem
                imageURL="https://s3-us-west-1.amazonaws.com/resonancestudios/language_logos/sketch_logo_x.png"
                title="Sketch"
              />
              <ConsultingTechnicalItem
                imageURL={imageAmplitudeLogo}
                title="Amplitude"
              />
              <ConsultingTechnicalItem
                imageURL={imagePostHogLogo}
                title="PostHog"
              />
              <ConsultingTechnicalItem
                imageURL={imageFirebaseLogo}
                title="Firebase"
              />
            </TechnicalItemsHolder>
            <ConsultingSeparator />
            <ConsultingBlurbHeader>
              Testimonials
            </ConsultingBlurbHeader>
            <TestimonialSection>
              <Testimonial>
                <ConsultingProfile src="https://s3-us-west-1.amazonaws.com/resonancestudios/aditya_testimonial.jpg" />
                <TestimonialTextBlock>
                  <TestimonialTitle>Aditya Sharma</TestimonialTitle>
                  <TestimonialSubtitle>
                    Founder and CEO @ Untether Labs
                  </TestimonialSubtitle>
                  <TestimonialQuote>
                    <QuoteHolder>
                      <QuoteIcon color="rgb(255, 255, 2550, 0.5)" size={25} />
                    </QuoteHolder>
                    Eugene was a pleasure to work with and helped us
                    quickly push new image processing features in React-Native and
                    Swift to our latest project.
                  </TestimonialQuote>
                </TestimonialTextBlock>
              </Testimonial>
              <Testimonial>
                <ConsultingProfile src="https://s3-us-west-1.amazonaws.com/resonancestudios/alan_testimonial.jpg" />
                <TestimonialTextBlock>
                  <TestimonialTitle>Alan Zhu</TestimonialTitle>
                  <TestimonialSubtitle>
                    Senior Software Engineer @ JITX
                  </TestimonialSubtitle>
                  <TestimonialQuote>
                    <QuoteHolder>
                      <QuoteIcon color="rgb(255, 255, 2550, 0.5)" size={25} />
                    </QuoteHolder>
                    Eugene is one the rare engineers I&apos;ve met that&apos;s as
                    good at engineering as he is at UI/UX, and product strategy.
                    He took the time to co-work together which was a big plus,
                    and helped clarify several concepts in Swift and Node.js
                  </TestimonialQuote>
                </TestimonialTextBlock>
              </Testimonial>
            </TestimonialSection>
          </ConsultingBlock>
        </ConsultingSection>
        <StandingWaveHolder>
          <StandingWave height={300} />
        </StandingWaveHolder>
        <FooterDisclaimer>
          Designed by Eugene M. Joseph
        </FooterDisclaimer>
      </PageScroll>
    </div>
  );
}

export default MainPage;
